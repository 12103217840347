<template>
  <div>
    <div
      class="homepage"
      :class="[empty(event.backgroundImage) && 'secondary']"
      :style="
        !empty(event.backgroundImage) && {
          background: `url(${event.backgroundImage}) no-repeat 50% 50%`,
          backgroundSize: 'cover',
        }
      "
    >
      <div v-if="loadingEvent" class="spinner-container">
        <div class="pulse-loader">
          <pulse-loader :loading="true" />
        </div>
      </div>
      <div v-else-if="invalidEvent" class="spinner-container">
        <Alert message="Invalid Event ID. Please try again." />
      </div>
      <div
        v-else
        class="home-container"
        :style="{
          height: isOverScreen ? '100%' : '100vh',
        }"
      >
        <v-card :loading="loading" :disabled="loading" class="register-container rounded-xl" :class="[!isLoginFromList && 'anonymous']" id="registrationCard" elevation="10">
          <template slot="progress">
            <v-progress-linear color="secondary" height="10" indeterminate />
          </template>
          <v-card-title>
            <template v-if="empty(event.charityLogo)">
              <XLogo class="event-dummy-logo" />
              <span class="event-dummy-text">
                <p>CHARITY</p>
                <p>LOGO</p>
              </span>
            </template>
            <img v-else class="event-logo event-logo-homepage" :src="event.charityLogo" />
          </v-card-title>
          <v-card-title class="text-center">
            {{ event.welcomeMessage }}
          </v-card-title>
          <template>
            <v-card-subtitle style="margin-top: 10px"> Enter your details below to join the event. </v-card-subtitle>
            <v-card-text>
              <v-form ref="form" v-model="registrationFormValid" lazy-validation class="mb-7">
                <template v-if="isLoginFromList">
                  <v-text-field :color="colorFromEvent" v-model="email" :rules="emailRules" label="E-mail" outlined required />
                  <v-text-field :color="colorFromEvent" v-model="password" :rules="passwordRules" label="Password" outlined required type="password" />
                </template>
                <template v-else>
                  <v-text-field :color="colorFromEvent" v-model="firstname" :rules="nameRules" label="First Name" outlined required />
                  <v-text-field :color="colorFromEvent" v-model="lastname" :rules="nameRules" label="Last Name" outlined required />
                  <v-text-field :color="colorFromEvent" v-model="email" :rules="emailRules" label="E-mail" outlined required />
                  <v-checkbox v-model="acceptedTerms" required :rules="privacyRules" :color="colorFromEvent">
                    <template v-slot:label>
                      <div>
                        I agree to the
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <a target="_blank" href="https://video.isilive.ca/cdn/givergy_privacy.html" @click.stop v-on="on" style="color: black"> Privacy Terms </a>
                          </template>
                        </v-tooltip>
                      </div>
                    </template>
                  </v-checkbox>
                </template>

                <vue-recaptcha sitekey="6LeDb30jAAAAADBZ81GBpzT-VTHEpOJQTKsDk9SX" @verify="submitRegistration" size="invisible" ref="invisibleRecaptcha" />
                <v-alert dense outlined text type="error" v-if="!empty(error)">
                  {{ error }}
                </v-alert>
              </v-form>
              <template v-if="isLoginFromList">
                <v-btn :disabled="!registrationFormValid || loading" :color="colorFromEvent" outlined elevation="4" rounded block @click="submitRegistration()"> Continue </v-btn>
              </template>
              <template v-else>
                <v-btn :disabled="!registrationFormValid || loading" :color="colorFromEvent" outlined elevation="4" rounded block @click="submitRegistration()"> Sign Up </v-btn>
              </template>
              <template v-if="hasAnonymousRegister && !isLoginFromList">
                <v-divider class="my-3" />
                <v-btn :color="colorFromEvent" id="submit_registration_button" elevation="4" rounded block class="white--text" @click="submitAnonymousRegistration"> Continue as Anonymous </v-btn>
              </template>
            </v-card-text>
          </template>
        </v-card>

        <div class="register-help d-flex align-center">
          <div class="powered-by">
            <v-chip large
              >powered by:
              <img src="givergy_logo_100x25.png" />
            </v-chip>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VueRecaptcha from 'vue-recaptcha';
import XLogo from '@/assets/images/XLogo';
import Alert from '@/components/alert';
import { mapState } from 'vuex';
import GuestService from '@/services/GuestService';
import EventService from '@/services/EventService';
import PulseLoader from 'vue-spinner/src/PulseLoader';
import { empty } from '@/utils';

export default {
  name: 'EventSignUp',
  props: {
    contentHeight: {
      type: Number,
    },
  },
  components: {
    VueRecaptcha,
    XLogo,
    Alert,
    PulseLoader,
  },

  data: () => ({
    loadingEvent: true,
    invalidEvent: false,
    registrationFormValid: false,
    firstname: '',
    lastname: '',
    nameRules: [v => !!v || 'Name is required'],
    privacyRules: [v => !!v || 'Accepting the privacy terms is required'],
    email: '',
    emailRules: [v => !!v || 'E-mail is required', v => /.+@.+/.test(v) || 'E-mail must be valid'],
    password: '',
    passwordRules: [v => !!v || 'Password is required'],
    acceptedTerms: false,
    recaptcha: null,
    isOverScreen: true,
    error: '',
    loading: false,
    successMessage: '',
  }),
  computed: {
    ...mapState({
      event: 'event',
      eventParams: 'eventParams',
      eventColor: 'eventColor',
    }),
    event_id() {
      return this.$route.params.event_id;
    },
    colorFromEvent() {
      const { primaryColour, secondaryColor } = this.eventColor;
      return primaryColour == '#ffffff' ? secondaryColor : primaryColour;
    },
    isLoginFromList() {
      return this.event.loginMode === 'CUSTOM_LIST';
    },
    hasAnonymousRegister() {
      return this.event.settings.isAnonymousLogin;
    },
  },
  watch: {
    contentHeight(value) {
      this.isOverScreen = value === 0 || window.screen.height < value;
    },
    event: {
      async handler(value) {
        this.invalidEvent = empty(value?.id);
      },
      immediate: true,
    },
  },
  beforeMount() {
    this.startTime = new Date().getTime();
  },
  beforeDestroy() {
    console.log('Time of registration', new Date().getTime() - this.startTime);
  },
  async created() {
    if (empty(this.event) && !empty(this.eventParams)) {
      this.loadingEvent = true;
      try {
        const { eventId, password } = this.eventParams;
        await this.$store.dispatch('fetchEvent', [eventId, password]);
        // eslint-disable-next-line no-empty
      } catch (_) {
      } finally {
        this.loadingEvent = false;
      }
    } else {
      this.loadingEvent = false;
    }
  },
  methods: {
    empty,
    openLink(url) {
      window.open(url, '_blank');
    },

    async verify() {
      await this.$refs.invisibleRecaptcha.execute();
    },
    submitAnonymousRegistration() {
      function randomIntFromInterval(min, max) {
        // min and max included
        return Math.floor(Math.random() * (max - min + 1) + min);
      }
      this.email = `anonymous${randomIntFromInterval(10000, 99999)}@isilive.com`;
      this.firstname = 'Anon';
      this.lastname = 'Anon';
      this.acceptedTerms = true;
      this.submitRegistration('', false);
    },

    async submitRegistration(response, hasRecaptcha = true) {
      this.error = '';
      this.recaptcha = response + '';
      this.$refs.form.validate();
      this.loading = true;
      try {
        if (this.registrationFormValid && (!hasRecaptcha || this.recaptcha)) {
          if (this.isLoginFromList) {
            // LOGIN from LIST
            try {
              const resp = await EventService.doCustomerLogin(this.event_id, {
                email: this.email,
                password: this.password,
              });

              this.$store.dispatch('setUserInfo', [resp.data, this.event_id]);
              this.$store.dispatch('setTracking', this.event_id);
              this.$gtag.event('Viewer Registered', {
                method: 'Customer',
              });
            } catch (error) {
              console.log({ error });
              const resp = error.response?.data;
              if (!resp?.success) {
                this.error = resp.message;
              }
            }
          } else {
            const resp = await GuestService.registerGuest({
              email: this.email,
              firstName: this.firstname,
              lastName: this.lastname,
              name: `${this.firstname} ${this.lastname}`,
              eventId: this.event_id,
            });
            await this.$store.dispatch('setUserInfo', [resp.data, this.event_id]);
            this.$store.dispatch('setTracking', this.event_id);
            this.$gtag.event('Viewer Registered', {
              method: hasRecaptcha ? 'Google' : 'Anonymous',
            });
          }
          if (empty(this.error)) {
            this.$router.push({ path: 'event/' + this.event_id });
          }
        }
      } catch (error) {
        console.log({ error });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
// .homepage {
//   background: url('https://dev-gp-main.s3.eu-west-2.amazonaws.com/4b1a0637-a4a3-4670-a0f7-029c9b1a048b.jpeg');
//   background-position: center;
//   background-repeat: no-repeat;
//   background-size: cover;
// }
.grecaptcha-badge {
  visibility: hidden;
}
.v-divider {
  border-width: 1px;
  border-radius: 5px;
}
.alert-success {
  border-radius: 4px !important;
}
</style>
